<template>
  <div>
    <b-form-select
      id="faculty-list-select"
      class="mr-sm-2 mx-0"
      v-model="local_company_id"
      :options="companyOptions"
    ></b-form-select>
  </div>

</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'CompanyList',
  props: ['company_id'],
  components: {

  },
  mixins: [ toasts ],
  emits: ['update:company_id'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  data() {
    return {
      local_company_id: {},
      companyOptions: [],
      selectedCompanyId: null
    };
  },
  watch: {
    local_company_id(newValue) {
      this.$emit('update:company_id', newValue);
    }
  },
  mounted() {
    if (this.company_id) {
      this.local_company_id = { ...this.company_id }
    }
    this.selectedCompanyId = this.preselectedCompanyId;
    this.loadCompanies();
  },
  methods: {
    loadCompanies() {
      axios
        .get(`/company`)
        .then(res => {
          if (res.status === 200) {
            this.companyOptions = res.data.map(item => ({ value: item.company_id, text: item.name }));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta föreningar');
        });
    },

  }
};
</script>
