<template>
  <div>
    <b-row class="justify-content-end my-4 mx-1">

      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm my-2"
        @click.prevent="upload_excel_clicked"
        ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('PAGES.EDUCATIONS.IMPORT_EDUCATIONS')}}</a
      >

      <input hidden type="file" id="excelFile" ref="excelFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xslx, .xls" v-on:change="handleExcelFileUpload()" />

    </b-row>

    <EducationImportJobTable
      :import_jobs="import_jobs"
      @on_job_selected="on_job_selected"
    />


    <EducationImportJobEditor
      v-if="job_record"
      ref="education-import-editor"
      :job_record="job_record"
    />
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import EducationImportJobTable from '@/view/pages/ml/education/EducationImportJobTable.vue';
import EducationImportJobEditor from '@/view/pages/ml/education/EducationImportJobEditor.vue';

export default {
  name: 'education-import-jobs',
  mixins: [ toasts ],
  components: {
    EducationImportJobTable,
    EducationImportJobEditor,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS'])
  },

  // clear interval on destroy
  beforeDestroy() {
    clearInterval(this.poll_job_interval_id);
    clearInterval(this.poll_all_jobs_interval_id);
  },

  data() {
    return {
      job_record: null, // selected job record
      import_jobs: [], // all import jobs
      poll_all_jobs_interval_id: null,
      poll_job_interval_id: null,
      job_errors: []
    };
  },
  watch: {
    /// when job selected, make sure its polling
    job_record: {
      handler: function (val, oldVal) {
        if (val) {
          this.start_poll_job();
        }
      },
      deep: true
    }
  },

  async mounted() {

    await this.poll_jobs();
    await this.poll_jobs_pagination()
    // poll all jobs and update status
    await this.start_poll_all_jobs();
  },

  methods: {

    async on_job_selected(job_id) {
      console.log('selected ' + job_id);

      this.job_record = await this.get_job(job_id);
    },


    handleExcelFileUpload() {
      this.file = this.$refs.excelFile.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();

      this.file = this.$refs.excelFile.value = null;
    },

    upload_excel_clicked() {
      this.$refs.excelFile.click();
    },

    async get_job_errors(job_id) {
      const res = await axios.get(`/job/errors/${job_id}`);
      if (res.status === 200) {
        return res.data;
      }

      return []
    },

    uploadFile() {
      const loader = this.$loading.show();

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_OTHER');
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      this.import_error_records = [];

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async res => {

          if (res.status === 201) {
            try {
              const file_obj = res.data.fileobjs[0];
              const job_result = await axios.post(`/education/import`, { file_id: file_obj.file_id });
              loader & loader.hide();

              if (job_result.status === 201) {
                this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FILES.UPLOADED'));

                this.job_record = job_result.data;

                this.start_poll_job();
              }
              else {
                this.toastr('danger', this.$t('COMMON.ERROR'), job_result.data.reason);
              }
            }
            catch (err) {
              console.error('import error', err);
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
              loader & loader.hide();
            }
          }
          else {
            loader & loader.hide();
          }

          /*this.pdf_file = res.data.fileobjs[0];
          this.pdf_filename = this.pdf_file.name;
          this.pdf_uploaded = true;
          this.pdf_style = this.ok_style;

          this.files_uploaded = this.csv_uploaded;*/


        })
        .catch(err => {
          loader & loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        });
    },


    async start_poll_all_jobs() {
      // check if polling
      if (this.poll_all_jobs_interval_id !== null) {
        return;
      }

      this.poll_all_jobs_interval_id = setInterval(this.poll_jobs, 8000);
    },

    async poll_jobs() {

      console.log('polling');
      try {
        const result = await axios.get('/job/type/IMPORT_EDUCATION');

        if (result.status === 200) {
          this.import_jobs = result.data;
        }
      }
      catch (err) {
        console.error(err);
      }
    },

    // async poll_jobs_pagination() {
    //   console.log('polling');
    //   try {
    //     const result = await axios.post('/job/type/IMPORT_EDUCATION/pagination');
    //     console.log('result', result);
    //     // if (result.status === 200) {
    //     //   this.import_jobs = result.data;
    //     // }
    //   }
    //   catch (err) {
    //     console.error(err);
    //   }
    // },

    new_clicked() {
      this.$refs['new-import-modal'].show();

      this.import_record = null;
    },

    stop_poll_job() {
      if (this.poll_job_interval_id) {
        clearInterval(this.poll_job_interval_id);
        this.poll_job_interval_id = null;
      }
    },

    start_poll_job() {

      if (this.poll_job_interval_id) {
        return;
      }

      const that = this;

      this.poll_job_interval_id = setInterval(() => {

        that.poll_job();

      }, 5000);
    },

    async poll_job() {
      try {

        if (this.job_record === null) {
          this.stop_poll_job();
          return;
        }

        const record = await this.get_job(this.job_record.id);

        this.job_record.status = record.status;
        // Vue3 compatability for Vue.set
        this.job_record = { ...this.job_record };

        if (record.status === 'FINISHED') {
          // no need to poll finished import
          this.stop_poll_job();
        }
      }
      catch (err) {
        console.error('poll error', err);
      }
    },


    tab_for_status(status) {

      switch (status) {
        case 'WAIT_FILE_UPLOAD': {
          return 'options';
        }
        case 'WAIT_MAP_COLUMNS':
        case 'DONE_FILE_UPLOADED': {
          return 'map_columns';
        }
        case 'WAIT_FINALIZE':
        case 'RUN_FINALIZE':
        case 'DONE_VALIDATION': {
          return 'finalize';
        }
        case 'DONE_MAP_COLUMNS':
        case 'RUN_VALIDATION':
        case 'WAIT_VALIDATION': {
          return 'validation';
        }
      }

      return 'options';
    },

    async get_job(id) {
      try {
        const result = await axios.get(`/job/poll/${id}`);

        if (result.status === 200) {
          return result.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.NOT_FOUND'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.ERROR'));
      }

      return null;
    },

  }
};
</script>
